import React from 'react';
import { Box, Avatar, Text, Image, Grid, AccordionPanel, Heading, Accordion } from 'grommet';
import Separator from '../separator';


const Team = ({ employees = [], size }) => {
    const [selectedIndex, setSelectedIndex] = React.useState(1);
    const onSelectIndex = (index) => {
        setSelectedIndex(index);
    }
    return (
        size === 'small' ?
            <TeamMobile employees={employees} /> :
            <Box gap="medium" direction='row'>
                <Box style={{ width: '40%' }}>
                    {employees.map((em, index) =>
                        <Member
                            key={index}
                            selected={index === selectedIndex}
                            onClick={() => onSelectIndex(index)}
                            employee={em}
                        />
                    )}
                </Box>
                <Box style={{ width: '60%' }} gap="medium">
                    <Box gap="small">
                        <SubHeading>À propos</SubHeading>
                        <Box gap="small" justify="center">
                            <Text>
                                Souriante, attentionnée, à l'écoute de toute sa clientèle, bonne relationnelle.
                                Elle est spécialiste en manucure, pédicure, pose de vernis classique et semi permanent, faux ongles, et une épilation efficace sans douleur.
                            </Text>
                        </Box>
                    </Box>
                    <Box gap="small">
                        <SubHeading>Portfolio</SubHeading>
                        <Box gap="small" justify="center">
                            <Grid columns={'xsmall'} gap="xsmall">
                                {employees[selectedIndex]?.portfolioImages?.slice(0, 12)?.map((img, index) => (
                                    <Box key={index} style={{ aspectRatio: '1' }} >
                                        <Image src={img.uris['800x1066']} fit="cover" />
                                    </Box>
                                ))}
                            </Grid>
                        </Box>
                    </Box>
                </Box>
            </Box>
    )
}
// {selecteds.map((s, index) => <Member key={index} onClick={() => onSelected(index)} selected={s} />)}
const SubHeading = ({ children, size }) => <Text size={size} style={{ fontWeight: '600', color: '#4d4d4d' }}>{children}</Text>

const Member = ({ selected, onClick, employee = {}, size = 'medium' }) => {
    const style = selected ? { borderLeft: '5px solid #E92C70', paddingLeft: 15 } : {};
    return (
        <Box gap="small" onClick={onClick} style={{ boxShadow: 'none', width: '100%', paddingTop: 10, paddingBottom: 10 }}>
            <Box direction='row' gap="medium" justify="between" style={style}>
                <Box direction="row" gap="small" justify="center" align="center">
                    <Avatar src={employee.image?.uris[size === 'small' ? '68x68' : '256x256']} />
                    <SubHeading size={size} >{employee.name}</SubHeading>
                </Box>
                <Box gap="small" justify="center">
                    <Box direction='row' gap='xsmall' justify="center" align="center">
                        <span style={{ fontWeight: 'bold', fontSize: getFontSize(size), color: '#ffb500' }}>{employee?.employeeReviews?.displayRating}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                        <svg xmlns="http://www.w3.org/2000/svg" width={getIconSize(size)} height={getIconSize(size)} viewBox="0 0 24 24"><path fill="#ffb500" fill-rule="evenodd" d="M6.92 22.639c-1.237.925-2.513-.047-2.056-1.567l1.873-6.24-5.044-3.895C.472 9.994.948 8.41 2.472 8.41h6.295l1.967-6.276c.476-1.517 2.058-1.505 2.53 0L15.23 8.41h6.295c1.533 0 2 1.586.779 2.528l-5.044 3.895 1.874 6.24c.456 1.52-.82 2.492-2.057 1.567L12 18.84l-5.08 3.799z"></path></svg>
                    </Box>
                    <Text size={size} textAlign="end" style={{ color: selected ? '#001e62' : '#707070' }}>{employee.employeeReviews?.count || 5} avis</Text>
                </Box>
            </Box>
            {size !== 'small' && <Separator />}
        </Box>

    )
}

const getIconSize = size => {
    if (size === 'small') return 14;
    return '20';
}

const getFontSize = size => {
    if (size === 'small') return 15;
    return 24;
}

const TeamMobile = ({ employees = [] }) => (
    < Box direction='row' style={{ width: '100%' }} gap="medium" align="start" >
        <Accordion style={{ width: '100%' }}>
            {
                employees.map((em, index) => (
                    <RichPanel em={em} key={index}>
                        <Box style={{ width: '100%' }} gap="medium">
                            <Box gap="small">
                                <SubHeading size='small'>À propos</SubHeading>
                                <Box gap="small" justify="center">
                                    <Text size='small'>
                                        Souriante, attentionnée, à l'écoute de toute sa clientèle, bonne relationnelle.
                                        Elle est spécialiste en manucure, pédicure, pose de vernis classique et semi permanent, faux ongles, et une épilation efficace sans douleur.
                                    </Text>
                                </Box>
                            </Box>
                            <Box gap="small" style={{paddingBottom: 10}}>
                                <SubHeading size='small'>Portfolio</SubHeading>
                                <Box gap="small" justify="center">
                                    <Grid columns={'xxsmall'} gap="xsmall">
                                        {em?.portfolioImages?.slice(0, 12)?.map((img, index) => (
                                            <Box key={index} style={{ aspectRatio: '1' }} >
                                                <Image src={img.uris['800x1066']} fit="cover" />
                                            </Box>
                                        ))}
                                    </Grid>
                                </Box>
                            </Box>
                        </Box>
                    </RichPanel>
                ))
            }
        </Accordion>
    </Box >
)
const RichPanel = ({ children, em }) => {
    const [hovering, setHovering] = React.useState(false);

    const renderPanelTitle = () => (
        <Member employee={em} size='small' />
    );

    return (
        <AccordionPanel
            label={renderPanelTitle()}
            onMouseOver={() => setHovering(true)}
            onMouseOut={() => setHovering(false)}
            onFocus={() => setHovering(true)}
            onBlur={() => setHovering(false)}
        >
            {children}
        </AccordionPanel>
    );
};

export default Team;