import { Box, Image } from "grommet";
import React from "react";
import opi from '../../../images/opi.png';
import dnd from '../../../images/dnd.jpeg';
import lechat from '../../../images/lechat.jpeg';

const Marques = () => {
    return(
        <Box direction='row' gap='small'>

            <Box background={"#e8e8e8"}>
                <Box pad="medium" justify="center" align="center"><Image style={{height: 50}} src={opi} /></Box>
            </Box>
            <Box background={"#e8e8e8"}>
                <Box pad="medium" justify="center" align="center" alignSelf="center" alignContent="center"><Image style={{height: 40}} src={lechat} /></Box>
            </Box>
            <Box background={"#e8e8e8"}>
                <Box pad="medium" justify="center" align="center" alignSelf="center" alignContent="center"><Image style={{height: 40, filter: "grayscale(100%)"}} src={dnd} /></Box>
            </Box>
        </Box>
    )
}


export default Marques;