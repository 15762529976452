import { Box } from 'grommet';
import React from 'react';

import { populaires } from './data';
import PrestationItem from './item';

const Populaires = ({size}) => {
    return (
        <Box style={{ width: '100%' }}>
            {
                populaires.map((item, index) => (
                    <PrestationItem
                        key={index}
                        item={item}
                        size={size === 'small' ? 'small' : 'medium'}
                        isEnding={index === populaires.length - 1}
                    />
                ))
            }
        </Box>
    )
}
export default Populaires;