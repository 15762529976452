import GoogleMapReact from 'google-map-react';
import { Box, Text } from 'grommet';
import { Location } from 'grommet-icons';
import React from 'react';
import './index.css';


const About = ({ size }) => {
    const lat = 48.8446864, lng = 2.417783199999999;
    const renderMarkers = (map, maps) => {
        let marker = new maps.Marker({
            position: { lat: lat, lng: lng },
            map,
            title: 'Be Perfect Manucure'
        });
        return marker;
    };
    return (
        <Box>
            <Box width='xlarge' style={{ backgroundColor: '#f8f8f8' }} direction={size === 'small' ? 'column' : 'row'} gap="small">
                <Box style={{ width: size === 'small' ? '100%' : '70%', height: '30vh' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: 'AIzaSyB9n7vwM3pC2E0O_X96m3VSbloepeaDOsA' }}
                        defaultCenter={{ lat: lat, lng: lng }}
                        defaultZoom={15}
                        onGoogleApiLoaded={({ map, maps }) => renderMarkers(map, maps)}
                    >
                    </GoogleMapReact>
                </Box>
                <Box direction='row' gap={size === 'small' ? 'medium' : 'small'} pad='small'>
                    <Box><Location /></Box>
                    <Box>
                        <Text style={{ fontWeight: 'bold' }} size={size}>Be Perfect Manucure</Text>
                        <Text size={size}>15 Avenue du Général de Gaulle 94160 Saint-Mandé</Text>
                    </Box>
                </Box>
            </Box>
            <Box direction={size === 'small' ? 'column' : 'row'} style={{ marginTop: 40 }} gap={size === 'small' ? 'medium' : 'small'} >
                <Box style={{ width: size === 'small' ? '100%' : '70%' }} pad='small'>
                    <Text>
                    Nos techniciennes expérimentées s'engagent à ce que votre expérience avec elles vous laisse détendue, choyée et prête à affronter votre journée.<br/>
                    Nos clients sont satisfaits de nos services de beauté des mains, le nail art et les faux ongles.<br/>
                    Nous sommes aussi spécialisés dans le dégradé français et les Baby-boomers sur ongles naturels.<br/>
                    On vous offre une expérience agréable de confort et de détente totale dans un environnement propre et relaxant.
                    </Text>
                    <br/><br/>
                    <Text>
                    <b>SPÉCIALITÉ :</b><br/>
                        Manucure, vernis semi-permanent, faux ongles, nail arts, Baby boomers, embouts acryliques, acrylique, gel, remplissage rose et blanc, manucure gel et French Gel, changement de vernis et de vernis gel
                    </Text>
                </Box>
                <Box style={{ width: size === 'small' ? '100%' : '30%' }} gap='small'>
                    <Box direction='row' justify='between'>
                        <Box direction='row' gap='xsmall' justify='center' align='center'>
                            <div className='open'></div>
                            <Text>Lundi</Text>
                        </Box>
                        <Box><Text>10:00 - 19:30</Text></Box>
                    </Box>
                    <Box direction='row' justify='between'>
                        <Box direction='row' gap='xsmall' justify='center' align='center'>
                            <div className='open'></div>
                            <Text>Mardi</Text>
                        </Box>
                        <Box><Text>10:00 - 19:30</Text></Box>
                    </Box>
                    <Box direction='row' justify='between'>
                        <Box direction='row' gap='xsmall' justify='center' align='center'>
                            <div className='open'></div>
                            <Text>Mercredi</Text>
                        </Box>
                        <Box><Text>10:00 - 19:30</Text></Box>
                    </Box>
                    <Box direction='row' justify='between'>
                        <Box direction='row' gap='xsmall' justify='center' align='center'>
                            <div className='open'></div>
                            <Text>Jeudi</Text>
                        </Box>
                        <Box><Text>10:00 - 19:30</Text></Box>
                    </Box>
                    <Box direction='row' justify='between'>
                        <Box direction='row' gap='xsmall' justify='center' align='center'>
                            <div className='open'></div>
                            <Text>Vendredi</Text>
                        </Box>
                        <Box><Text>10:00 - 19:30</Text></Box>
                    </Box>
                    <Box direction='row' justify='between'>
                        <Box direction='row' gap='xsmall' justify='center' align='center'>
                            <div className='open'></div>
                            <Text>Samedi</Text>
                        </Box>
                        <Box><Text>10:00 - 19:30</Text></Box>
                    </Box>
                    <Box direction='row' justify='between'>
                        <Box direction='row' gap='xsmall' justify='center' align='center'>
                            <div className='open'></div>
                            <Text>Dimanche</Text>
                        </Box>
                        <Box><Text>10:00 - 19:30</Text></Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default About;