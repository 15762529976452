import { Accordion, AccordionPanel, Box, Text } from 'grommet';
import React from 'react';
import { prestations } from '../prestation/data';
import PrestationItem from '../prestation/item';


const Separator = ({ selected }) => <hr style={{
    height: 1,
    width: '100%',
    border: 0, backgroundColor: selected ? '#fff' : '#e8e8e8',
    margin: 0, padding: 0,
}} />

const Menu = ({ size }) => {

    const [selectedIndex, setSelectedIndex] = React.useState(0);

    const onSelected = (index) => {
        setSelectedIndex(index);
    }

    return (
        size === 'small' ?
            <MenuMobile /> :
            <Box direction='row' style={{ width: '100%' }} gap="medium" align="start">
                <Box style={{ width: '30%' }}>
                    {prestations.map((prestation, index) => (
                        <SubMenu
                            key={index}
                            prestation={prestation}
                            selected={index === selectedIndex}
                            onClick={() => onSelected(index)}
                            separatorHidden={index === selectedIndex - 1}
                        />
                    ))}
                </Box>
                <Box style={{ width: '70%' }}>
                    {selectedIndex >= 0 &&
                        prestations[selectedIndex].data.map((item, index) => (
                            <PrestationItem
                                key={index}
                                item={item}
                                isEnding={index === prestations[selectedIndex].data.length - 1}
                            />
                        ))
                    }
                </Box>
            </Box>
    )
}

const SubMenu = ({ selected, onClick, separatorHidden, prestation }) => {
    return (
        <Box>
            <Box direction='row' style={{ width: '100%', boxShadow: 'none', backgroundColor: selected ? "#e8e8e8" : "#fff" }}
                onClick={onClick}
                gap="small" pad="small" round={{ corner: "right" }} align="center">
                <Text>{prestation.title}</Text>
                <Text>({prestation.data.length})</Text>
            </Box>
            {!separatorHidden && <Separator selected={selected} />}
        </Box>
    )
}

const MenuMobile = () => {
    return (
        <Box direction='row' style={{ width: '100%' }} gap="medium" align="start">
            <Accordion style={{width: '100%'}}>
                {
                    prestations.map((pres, index) => (
                        <AccordionPanel 
                            color='#707070' 
                            label={`${pres.title} (${pres.data.length})`}
                            key={index}
                        >
                            {
                                pres.data.map((item, index) => (
                                    <PrestationItem
                                        size={'small'}
                                        key={index}
                                        item={item}
                                        willExpand={false}
                                        isEnding={index === pres.data.length - 1}
                                    />
                                ))
                            }

                        </AccordionPanel>
                    ))
                }
            </Accordion>
        </Box>
    )
}

export default Menu;